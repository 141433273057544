import { Navigate, NavLink, useNavigate, useParams } from 'react-router-dom'
import { Button } from '@/components/Button'
import { useEffect, useState } from 'react'
import { BREADCRUMBS_PADDING_STYLE, FEATURE_RESTRICTIONS } from '@/constants'
import BreadCrumbs from '@/components/BreadCrumbs'
import { FaPencilAlt } from 'react-icons/fa'
import WrappedRoutingMapView from './ViewMap'
import { currentLocationAtom } from '@/store/route'
import { useAtomValue } from 'jotai'
import moment from 'moment-timezone'
import { formatDistanceToNow } from 'date-fns'
import useRoutePolyline from '@/hooks/useRoutePolyline'
import FieldSubscriptionRestricted from '@/components/FieldSubscriptionRestricted'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { getSubscriptions } from '@/api/profile'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { SubscriptionsInfoType } from '@/api/profile/schema'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'

const TimeAgo: React.FC<{ dateString: string | undefined }> = ({ dateString }) => {
    const [timeAgo, setTimeAgo] = useState('')

    useEffect(() => {
        if (dateString) {
            const parsedDate = moment(dateString).toDate()
            const timeDiff = formatDistanceToNow(parsedDate, { addSuffix: true })

            setTimeAgo(timeDiff)
        }
    }, [dateString])

    return (
        <div className='text-zentive-gray-medium'>Last Update: {dateString ? timeAgo : '--'}</div>
    )
}

const ViewRoute = () => {
    const currentLocation = useAtomValue(currentLocationAtom)
    const currentUser = useViewedAsUser()

    const navigate = useNavigate()
    const { routeId } = useParams()
    const { data, isLoading, isSuccess, error: routeError } = useRoutePolyline(routeId as string, currentUser?.businessId as string)

    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['routingFeatureSubscription', currentUser?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                currentUser?.businessId as string,
                FEATURE_RESTRICTIONS.liveTracking,
            ),
    })

    const { data: subscriptionsData } = useQuery<unknown, AxiosError, SubscriptionsInfoType[]>({
        queryKey: ['subscriptionsInfo'],
        queryFn: () => getSubscriptions(currentUser?.customerId as string),
        enabled: !!currentUser?.customerId,
    })

    const isFirstPlan: boolean = (subscriptionsData?.length ?? 0) <= 1

    if (isLoading || featureLoading) {
        return 'Loading...'
    }

    if (routeError || !data) {
        return <Navigate to='/404' />
    }

    if (isSuccess && data) {
        return (
            <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
                <BreadCrumbs titleName={`Route ${data.routeNumber}`} />

                <div className='mt-10 '>
                    {!isFirstPlan && !isFeatureAvailable && (
                        <div className='text-base bg-zentive-bg-200 rounded-sm px-[15px] mt-[24px] py-4'>
                            <span className='font-semibold text-zentive-blue-dark'>Note: </span>
                            Your subscription level has changed. Certain features are now restricted
                            or unavailable. Please&nbsp;
                            <NavLink
                                className='font-semibold text-zentive-blue-dark cursor-pointer'
                                to={`/owner-subscription/change-plan`}
                            >
                                <u>upgrade</u>
                            </NavLink>
                            &nbsp;to regain full access.
                        </div>
                    )}
                    <div className='w-full flex justify-end '>
                        <FieldSubscriptionRestricted isFieldRestricted={!isFeatureAvailable}>
                            <Button
                                className='mr-4  mb-2 text-base bg-white border'
                                onClick={() => navigate(`/schedule/routing/edit-route/${routeId}`)}
                                type='button'
                                variant='secondary'
                            >
                                <FaPencilAlt className='h-4 w-4 cursor-pointer mr-2 ' />
                                Edit Route
                            </Button>
                        </FieldSubscriptionRestricted>
                    </div>

                    <TimeAgo dateString={currentLocation?.lastLocationUpdate} />

                    <WrappedRoutingMapView />
                </div>
            </div>
        )
    }

    return null
}

export default ViewRoute
