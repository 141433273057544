import { useState, useEffect, useCallback, useRef } from 'react'
import { GoogleMap, PolylineF } from '@react-google-maps/api'
import Marker from './Marker'
import { CustomerProfileType } from '@/api/profile/schema'
import { mapBoundsAtom } from '@/store/location'
import { useAtom, useAtomValue } from 'jotai'
import { isLandmarkShownAtom } from '@/store/route'
import polyline from '@mapbox/polyline'
import AnimatedMarker from './GoogleMarker'
import { Navigate, useParams } from 'react-router-dom'
import useRoutePolyline from '@/hooks/useRoutePolyline'
import { WEBSOCKET_URL } from '@/api/axiosInstance'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const CONTAINER_STYLE = {
    borderRadius: '8px',
    width: '100%',
    height: '100%',
}

const MAP_OPTIONS = {
    keyboardShortcuts: false,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: true,
    zoomControl: true,
}

const Map = () => {
    const currentUser = useViewedAsUser()
    const { routeId } = useParams()
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [activeMarker, setMarker] = useState<string | null>(null)
    const [isMounted, setIsMounted] = useState<boolean>(false)
    const [previousBounds, setPreviousBounds] = useAtom(mapBoundsAtom)
    const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral | null>(null)
    console.log("🚀 ~ Map ~ currentLocation:", currentLocation)
    const isLandmarkShown = useAtomValue(isLandmarkShownAtom)

    // const setCurrentLoc = useSetAtom(currentLocationAtom)
    // const [location, setLocation] = useState<RouteLocation>({
    //     routeId: "9fac89b9-e054-433f-b2d9-9ee034faeaee", // Convert to string
    //     lat: 0,
    //     lng: 0,
    //     from: "owner",
    // })
    const ws = useRef<WebSocket | null>(null)
    const reconnectTimeoutRef = useRef<NodeJS.Timeout>()

    const { data, isLoading, isSuccess, error: routeError } = useRoutePolyline(routeId as string, currentUser?.businessId as string)

    const coordinates = data?.customers
    // const foremanLat = data?.foremanLat
    // const foremanLng = data?.foremanLat
    const polylines = data?.encodedPolyline
        ? polyline.decode(data.encodedPolyline)?.map(([lat, lng]) => ({ lat, lng }))
        : []

    // useEffect(() => {
    //     if (foremanLat && foremanLng && routeId) {
    //         const interval = setInterval(() => {
    //             getForemanLocation(routeId)
    //         }, MINUTE_MS)

    //         return () => clearInterval(interval)
    //     }
    // }, [foremanLat, foremanLng, routeId])

    // const { mutate: getForemanLocation } = useMutation({
    //     mutationFn: (data: string) => getCurrentLocation(data),
    //     onSuccess: (res) => {
    //         const newLocation = { lat: res.foremanLat, lng: res.foremanLng }
    //         setCurrentLoc(res)
    //         setCurrentLocation(newLocation)
    //     },
    // })

    const connect = () => {
        if (ws.current?.readyState === WebSocket.OPEN || 
            ws.current?.readyState === WebSocket.CONNECTING) return

        ws.current = new WebSocket(`${WEBSOCKET_URL}/live-tracking`,)
        // ws.current = new WebSocket('ws://localhost:8080/live-tracking',)   

        ws.current.onopen = () => {
            console.log('Connected to WebSocket server')
        }

        ws.current.onmessage = (event) => {
            const updatedRoutes = JSON.parse(event.data)
            setCurrentLocation({
                lat: updatedRoutes.lat,
                lng: updatedRoutes.lng
            })
        
        }

        ws.current.onclose = () => {
            console.log('WebSocket connection closed, retrying...')
            reconnectTimeoutRef.current = setTimeout(connect, 1000)
        }

        ws.current.onerror = (error) => {
            console.error('WebSocket error:', error)
        }
    }

    const handleLoad = useCallback((mapInstance: google.maps.Map) => {
        setMap(mapInstance)
    }, [])

    useEffect(() => {
        setIsMounted(true)
        return () => setMap(null)
    }, [])

    useEffect(() => {
        if (data && coordinates!?.length > 0 && map) {
            const bounds = new google.maps.LatLngBounds()
            coordinates!.forEach((coord: CustomerProfileType) => {
                bounds.extend(
                    new google.maps.LatLng(
                        coord.address.gmapLat as number,
                        coord.address.gmapLng as number,
                    ),
                )
            })

            setPreviousBounds(bounds)
        }
    }, [coordinates, map])

    useEffect(() => {
        if (!isMounted || !map || coordinates === undefined || !isSuccess || !data) return

        const bounds = new google.maps.LatLngBounds()

        coordinates.forEach((coord: CustomerProfileType) => {
            bounds.extend(
                new google.maps.LatLng(
                    coord.address.gmapLat as number,
                    coord.address.gmapLng as number,
                ),
            )
        })

        if (!coordinates?.length && previousBounds) {
            map.fitBounds(previousBounds)
        } else {
            map.fitBounds(bounds)
            setPreviousBounds(bounds)
        }

        if (coordinates?.length === 1) {
            map.setZoom(10)
        }
    }, [coordinates, isMounted, isSuccess, map])

    useEffect(() => {
        connect()
        return () => {
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current)
            }
            if (ws.current) {
                ws.current.close()
            }
        }
    },[])

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newLocation = {
                routeId: routeId,
                from: "owner"
            }
            
            if (ws.current?.readyState === WebSocket.OPEN) {
                ws.current.send(JSON.stringify(newLocation))
            }
        }, 10000) // Run every 10 seconds

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    
    if (routeError || !data) {
        return <Navigate to='/404' />
    }

    if (!isLoading && isMounted) {
        const modifiedMapOptions = {
            ...MAP_OPTIONS,
            styles: [
                {
                    featureType: 'poi',
                    stylers: [
                        {
                            visibility: isLandmarkShown ? 'on' : 'off',
                        },
                    ],
                },
            ],
        }

        return (
            <div className='relative w-full h-[650px]'>
                <GoogleMap
                    mapContainerStyle={CONTAINER_STYLE}
                    onLoad={handleLoad}
                    options={modifiedMapOptions}
                >
                    {currentLocation && (currentLocation.lat !== undefined && currentLocation.lng !== undefined) &&<AnimatedMarker position={currentLocation} />}

                    {coordinates?.map((coord, index) => {
                        return (
                            <Marker
                                activeMarker={activeMarker}
                                coordinate={coord}
                                key={coord.profileId}
                                position={{
                                    lat: coord.address.gmapLat as number,
                                    lng: coord.address.gmapLng as number,
                                }}
                                setMarker={setMarker}
                                text={`${index + 1}`}
                            />
                        )
                    })}

                    {polylines?.length > 0 && (
                        <PolylineF
                            path={polylines}
                            options={{
                                strokeColor: '#FF0000',
                                strokeOpacity: 1.0,
                                strokeWeight: 2,
                                icons: [
                                    {
                                        icon: {
                                            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                            scale: 2,
                                            strokeColor: '#FF0000',
                                            fillOpacity: 1,
                                            fillColor: '#FF0000',
                                        },
                                        offset: '0%',
                                        repeat: '100px',
                                    },
                                ],
                            }}
                        />
                    )}
                </GoogleMap>
            </div>
        )
    }

    return null
}

export default Map
