import { Lead } from '@/api/lead/schema'
import { Pagination } from '@/components/Pagination'
import { Table, TableBody, TableCell, TableRow } from '@/components/Table'
import { US_FORMAT_WITH_TIME_MERIDIEM } from '@/constants'
import { formatTo24HourClock } from '@/utils/time'
import { Mail } from 'lucide-react'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LeadNotFound from '../../LeadNotFound'
import Spinner from '@/components/Spinner'
import { useAtom, useSetAtom } from 'jotai'
import {
    leadToUpdateAtom,
    selectedLeadIdsAtom,
    viewedLeadAtom,
    toExportLeadAtom,
    selectedLeadsAtom,
} from '@/store/lead'
import { toCurrency } from '@/utils/helper'
import { Checkbox } from '@/components/Checkbox'
import { CheckedState } from '@radix-ui/react-checkbox'
import LeadsTableHeader from './LeadsTableHeader'
import { ModalVariants } from '..'
import { MODAL_VARIANTS } from '@/constants'
import FieldSubscriptionRestricted from '@/components/FieldSubscriptionRestricted'
import { leadPaginationAtom } from '@/store/paginationAtom'

type LeadsTableProps = {
    isLoading: boolean
    isSuccess: boolean
    leads: Lead[]
    setModalType: Dispatch<SetStateAction<ModalVariants>>
    totalRecords: number
    isFeatureAvailable: boolean
}

const LeadsTable: FC<LeadsTableProps> = ({
    isLoading,
    isSuccess,
    leads,
    setModalType,
    totalRecords,
    isFeatureAvailable,
}) => {
    const [pagination, setPagination] = useAtom(leadPaginationAtom)

    const updateExportedLeads = (leadIds: string[]) => {
        const leadsToExport = leads.filter((lead) => leadIds.includes(lead.leadId))
        setToExportLeads(leadsToExport)
    }

    const [selectedLeadIds, setSelectedLeadIds] = useAtom(selectedLeadIdsAtom)
    const [leadToUpdate, setLeadToUpdate] = useAtom(leadToUpdateAtom)

    const navigate = useNavigate()
    const setLead = useSetAtom(viewedLeadAtom)
    const setToExportLeads = useSetAtom(toExportLeadAtom)
    const setSelectedLeads = useSetAtom(selectedLeadsAtom)

    const handleBulkChange = (val: CheckedState): void => {
        setSelectedLeadIds(val ? leads.map((lead) => lead.leadId) : [])
        updateExportedLeads(val ? leads.map((lead) => lead.leadId) : [])
    }

    const handleSingleChange = (val: CheckedState, leadId: string): void => {
        setSelectedLeadIds((prev) =>
            val ? [...prev, leadId] : prev.filter((selectedLeadId) => selectedLeadId !== leadId),
        )
        updateExportedLeads(
            val ? [...selectedLeadIds, leadId] : selectedLeadIds.filter((id) => id !== leadId),
        )
    }

    const isCheckedAll =
        selectedLeadIds.length > 0 && leads.length > 0
            ? selectedLeadIds.length === leads.length
            : false

    useEffect(() => {
        if (leadToUpdate) setModalType(MODAL_VARIANTS.UPDATE)
    }, [leadToUpdate])

    useEffect(() => {
        setSelectedLeads([])
    }, [])

    return (
        <Table className='bg-white w-full rounded-[8px]'>
            <LeadsTableHeader
                checked={isCheckedAll}
                hasLeads={leads.length > 0}
                onCheckedChange={handleBulkChange}
                isFieldRestricted={!isFeatureAvailable}
            />
            <TableBody>
                {isLoading && (
                    <TableRow>
                        <TableCell colSpan={6}>
                            <Spinner variant='normal' className='h-[70px] w-[70px]' />
                        </TableCell>
                    </TableRow>
                )}

                {isSuccess &&
                    leads.length > 0 &&
                    leads.map((lead) => (
                        <TableRow key={lead.leadId} className='text-base'>
                            <TableCell
                                className={selectedLeadIds.includes(lead.leadId) ? 'py-3' : ''}
                            >
                                <FieldSubscriptionRestricted
                                    isFieldRestricted={!isFeatureAvailable}
                                >
                                    <Checkbox
                                        checked={selectedLeadIds.includes(lead.leadId)}
                                        onCheckedChange={(val) =>
                                            handleSingleChange(val, lead.leadId)
                                        }
                                    />
                                </FieldSubscriptionRestricted>
                            </TableCell>
                            <TableCell className='text-right'>
                                {toCurrency(lead.dealSize)}
                            </TableCell>
                            <TableCell>
                                <FieldSubscriptionRestricted
                                    isFieldRestricted={!isFeatureAvailable}
                                >
                                    <button
                                        className='text-zentive-green-dark underline'
                                        onClick={() => setLead(lead)}
                                        type='button'
                                    >
                                        {lead.firstName} {lead.lastName}
                                    </button>
                                </FieldSubscriptionRestricted>
                            </TableCell>
                            <TableCell className='text-zentive-green-dark'>
                                {lead.contactType}
                            </TableCell>
                            <TableCell className='text-zentive-green-dark'>{lead.source}</TableCell>
                            <TableCell>
                                <div className='flex flex-col'>
                                    {lead?.productServiceNames?.length ? (
                                        // Filter unique items using Set and map them
                                        [...new Set(lead.productServiceNames)].map((item) => (
                                            <div key={item}>{item}</div>
                                        ))
                                    ) : (
                                        <div>No products or services available</div>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell className={'text-zentive-green-dark'}>
                                <FieldSubscriptionRestricted
                                    isFieldRestricted={!isFeatureAvailable}
                                >
                                    <button
                                        onClick={() => setLeadToUpdate(lead?.leadId)}
                                        type='button'
                                    >
                                        {lead.label}
                                    </button>
                                </FieldSubscriptionRestricted>
                            </TableCell>
                            <TableCell>
                                {formatTo24HourClock(lead.createdAt, US_FORMAT_WITH_TIME_MERIDIEM)}
                            </TableCell>
                            <TableCell>
                                <FieldSubscriptionRestricted
                                    isFieldRestricted={!isFeatureAvailable}
                                >
                                    <button
                                        className='w-full flex justify-start items-center'
                                        onClick={() => {
                                            setSelectedLeads((prev) => [...prev, lead])
                                            navigate('/customers/lead-management/create-lead-message')
                                        }}
                                        type='button'
                                    >
                                        <Mail
                                            className={
                                                'h-5 ' +
                                                (!isFeatureAvailable
                                                    ? 'text-zentive-gray'
                                                    : 'text-zentive-green-dark')
                                            }
                                        />
                                    </button>
                                </FieldSubscriptionRestricted>
                            </TableCell>
                        </TableRow>
                    ))}

                {isSuccess && leads.length === 0 && (
                    <TableRow>
                        <TableCell colSpan={9}>
                            <LeadNotFound />
                        </TableCell>
                    </TableRow>
                )}
                <TableRow>
                    <TableCell colSpan={9} className='pb-0'>
                        <Pagination
                            itemsPerPage={pagination.pageSize ?? 5}
                            pagination={pagination}
                            setPagination={setPagination}
                            totalRecords={totalRecords}
                        />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default LeadsTable
